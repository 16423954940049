// -----------------------------
// CB Glyph Icons
// -----------------------------
@use "sass:math";

.cb-glyph {
  &:not(.cb-glyph-numeric) {
    @include glyph-font(CB Glyphs);
  }
}

.cb-glyph {
  @each $key, $value in $glyphs {
    &.cb-#{$key} {
      @include glyph-content($value);
    }
  }

  &.cb-search {
    background: none;
  }
}

// ------------------------------------ NUMERIC

.cb-glyph-numeric {
  @include border-box();
  display: inline-block;
  border-radius: 50%;
  font-size: 1rem;
  width: 2em;
  height: 2em;
  font-weight: 700;
  line-height: 1.82em !important;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      line-height: 2em !important;
    }
  }

  text-align: center;
  border: 0.125em solid $font-color-base;

  &::before {
    content: attr(data-cb-number);
  }

  &.cb-glyph-circular {
    color: $white;
    // border-color: $blue3;
    // background-color: $blue3;
    border-color: $blue5;
    background-color: $blue5;
  }
}

// ------------------------------------ CIRCULAR
.cb-glyph-circular {
  &:not(.cb-glyph-numeric) {
    position: relative;
    display: block;
    height: 2em;
    width: 2em;

    &::before {
      position: absolute;
      z-index: 2;
      left: math.div(1em, 2);
      top: math.div(1em, 2);
      color: $white;
    }

    &::after {
      content: " ";
      position: absolute;
      z-index: 1;
      display: block;
      border-radius: 50%;
      height: 2em;
      width: 2em;
      // background-color: $blue3;
      background-color: $blue5;
    }
  }
}

// ------------------------------------ AVATAR
.cb-glyph-avatar {
  &:not(.cb-glyph-numeric) {
    transition: all $transition-time-l ease;
    position: relative;
    display: block;
    font-size: 1rem;
    width: 2em;
    height: 2em;

    &::before {
      position: absolute;
      z-index: 2;
      font-size: 1.3125em;
      left: math.div(0.5em, 2);
      top: math.div(0.5em, 2);
      color: $white;
    }

    &::after {
      content: " ";
      position: absolute;
      z-index: 1;
      display: block;
      border-radius: 50%;
      width: 2em;
      height: 2em;
      background-color: $black1;
    }

    // md up
    @include media-breakpoint-up("md", $grid-breakpoints) {
      width: 3em;
      height: 3em;

      &::before {
        font-size: 2em;
      }

      &::after {
        width: 3em;
        height: 3em;
      }
    }
  }
}

// ------------------------------------ OLD SIZE

.cb-glyph,
.cb-glyph-numeric {
  &:not(.cb-glyph-avatar) {
    @each $key, $value in $glyphSize {
      &.cb-glyph-#{$key} {
        font-size: calc(1rem * #{$value});
      }
    }
  }
}

.cb-glyph {
  &.cb-glyph-avatar {
    &.cb-glyph-sm {
      width: 2em;
      height: 2em;

      &::before {
        font-size: 1.3125em;
        left: math.div(0.5em, 2);
        top: math.div(0.5em, 2);
      }

      &::after {
        width: 2em;
        height: 2em;
      }
    }

    &.cb-glyph-md {
      width: 3em;
      height: 3em;

      &::before {
        font-size: 2em;
      }

      &::after {
        width: 3em;
        height: 3em;
      }
    }
  }
}

// ------------------------------------ NEW SIZE PATTERN

$glyphSizeNew: (12, 14, 16);

@each $size in $glyphSizeNew {
  .cb-glyph {
    &.cb-glyph-#{$size} {
      font-size: #{$size}px;
    }

    &.cb-glyph-circular {
      $name: ($size * 2);

      &.cb-glyph-#{$name} {
        font-size: #{$size}px;
      }
    }
  }
}

// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $glyphSizeNew {
      .cb-glyph-#{$breakpoint}-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $glyphSizeNew {
      .cb-glyph-#{$breakpoint}-up-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $glyphSizeNew {
      .cb-glyph-#{$breakpoint}-down-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-glyph-circular {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$numGlyphSizeNew: (12, 14, 16, 24, 32);

@each $size in $numGlyphSizeNew {
  .cb-glyph-numeric {
    $name: ($size * 2);

    &.cb-glyph-#{$name} {
      font-size: #{$size}px;
      flex-shrink: 0;
    }

    &.cb-glyph-circular {
      $name: ($size * 2);

      &.cb-glyph-#{$name} {
        font-size: #{$size}px;
        flex-shrink: 0;
      }
    }

    &.cb-glyph-24 {
      line-height: 23px !important;
    }
  }
}

// Numeric Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $numGlyphSizeNew {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $numGlyphSizeNew {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-up-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $numGlyphSizeNew {
      .cb-glyph-numeric {
        $name: ($size * 2);

        &.cb-glyph-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }

        &.cb-glyph-circular {
          $name: ($size * 2);

          &.cb-glyph-#{$breakpoint}-down-#{$name} {
            font-size: #{$size}px !important;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

$contentGlyphSizeNew: (32, 36, 48);

@each $size in $contentGlyphSizeNew {
  .cb-glyph-content {
    &.cb-glyph-#{$size} {
      font-size: #{$size}px;
    }
  }
}

// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $contentGlyphSizeNew {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $contentGlyphSizeNew {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-up-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $contentGlyphSizeNew {
      .cb-glyph-content {
        &.cb-glyph-#{$breakpoint}-down-#{$size} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$multiGlyphSizeNew: (64, 72, 96);

@each $size in $multiGlyphSizeNew {
  .cb-glyph-content-multi {
    &.cb-glyph-#{$size} {
      font-size: #{$size}px;
      // for when we have a flex parent
      flex-shrink: 0;
    }
  }
}

// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $multiGlyphSizeNew {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-#{$size} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $multiGlyphSizeNew {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-up-#{$size} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $multiGlyphSizeNew {
      .cb-glyph-content-multi {
        &.cb-glyph-#{$breakpoint}-down-#{$size} {
          font-size: #{$size}px !important;
          flex-shrink: 0;
        }
      }
    }
  }
}

// ------------------------------------ COLOR

// Removing cb-glyph-corporate , XXX color options
@each $color, $map in $colorThemes {
  .cb-glyph {
    // &.cb-#{$color}-color {
    &.#{"cb-" + $color + "-color"} {
      &.cb-glyph-circular,
      &.cb-glyph-avatar {
        &::after {
          background-color: map-get($map, code);
        }
      }
    }
  }

  .cb-glyph-numeric {
    &.#{"cb-" + $color + "-color"} {
      border-color: map-get($map, code);

      &.cb-glyph-circular {
        color: $white !important;
        border-color: map-get($map, code);
        background-color: map-get($map, code);
      }
    }
  }
}

@media print {
  @include print-glyph-numeric();
}

.cb-print {
  @include print-glyph-numeric();
}
