// -----------------------------
// CB Glyph Content
// -----------------------------

.cb-glyph-content {
  position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
  font-size: 2rem;
	line-height: 1em;
  @include glyph-font(CB Glyphs Multi);
	&::before,
	&::after {
	  position: absolute;
	  top: -50%;
	  left: -50%;
    font-size: 2em;
    width: .5em;
    height: .5em;
	}

  @each $key, $map in $multiGlyph {
    &.cb-#{$key} {
      @include glyph-content(map-get($map, "before"), map-get($map, "after"));
    }
  }

  // ------------------------------------ SIZE

  @each $key, $value in $multiGlyphSize {
    &.cb-glyph-#{$key} {
      font-size: calc(.5rem * #{$value});
    }
  }
}
